<template>
  <div class="page404">
    <h1 class="title" id="title">
      <span class="theme-color">天美云</span>提醒您
    </h1>
    <div class="tips">
      请使用 <span class="theme-color">微信APP</span> 打开链接！
    </div>
  </div>
</template>

<script>
  import { reactive, onMounted, toRefs, nextTick } from 'vue'
  import { Toast } from 'vant'
  import store from '@/store'
  export default {
    name: 'unableplat',
    setup() {
      const state = reactive({
        showPop: false
      })
      onMounted(async () => {
      })
  
      // nextTick(() => {
      //   document.body.setAttribute('style', 'background:#f3f3f3')
      // })
  
  
      const tips = () => {
        Toast('敬请期待');
      }
  
      return {
        ...toRefs(state),
        tips
      }
    },
  }
</script>
<style lang="less" scoped>
  @import '../assets/style/mixin';

  .page404{
    min-height: 100vh;
    background-color: #f3f3f3;
    display:flex;
    flex-direction: column; 
  }
  .theme-color{
    color: @mainc;
  }
  
  .title{
    margin-top: 108px;
    text-align: center;
    font-size: 18px;
  }
  .tips{
    color: #999999;
    font-size: 15px;
    text-align: center;
    margin-top: 22px;
    line-height: 1.5;
  }
</style>